.gradient-skeleton-loader {
    display: flex;
    flex-direction: column;
    align-items: start;
  }
  
  .gradient-skeleton-line {
    height: 10px;
    width: 80%;
    margin-bottom: 10px;
    background: linear-gradient(90deg, #eee, #f5f5f5, #eee);
    background-size: 200% 100%;
    animation: loadingAnimation 1.5s infinite ease-in-out;
  }
  
  @keyframes loadingAnimation {
    0% {
      background-position: 200% 0;
    }
    100% {
      background-position: -200% 0;
    }
  }