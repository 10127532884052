.custom-nav-link {
    background-color: transparent;
}
.custom-nav-link:active {
    background-color: #FF9F43;
}
@media screen and (max-width: 480px) {
    .dash-counts h4{
        font-size: 18px;
        margin-bottom: 5px;
    }
    #apexchartssales-trending-data {
        height: 300px;
    }
    .dash-widget {
        background: #fff;
        margin-bottom: 10px;
        border-radius: 6px;
        padding: 10px;
        padding-top: 20px;
        padding-bottom: 20px;
        border: 1px solid #E8EBED;
    }
    .dash-widget.dash1 .dash-widgetimg span {
        background: rgba(40, 199, 111, 0.12);
    }
    .dash-widget.dash2 .dash-widgetimg span {
        background: rgba(0, 207, 232, 0.12);
    }
    .dash-widget.dash3 .dash-widgetimg span {
        background: rgba(234, 84, 85, 0.12);
    }
    .dash-widget .dash-widgetcontent {
        margin-left: 5px;
    }
    .dash-widget .dash-widgetcontent h5 {
        color: #212B36;
        font-weight: 700;
        font-size: 16px;
        margin-bottom: 5px;
    }
    .dash-widget .dash-widgetcontent h6 {
        font-weight: 400;
        font-size: 14px;
        color: #212B36;
    }
    .dash-widget .dash-widgetimg span {
        width: 48px;
        height: 48px;
        background: rgba(249, 110, 111, 0.12);
        border-radius: 50px;
    }
    .dash-widgetimg {
        display: none;
    }
    .dash-imgs {
        display: none;
    }

  }